<template>
  <div class="award__container">
    <div class="page__title scrollObj">awards</div>
    <div class="award__items-container">
      <div class="item__container" v-for="(award, index) in awards" :key="index">
        <div class="animation__line scrollObj"></div>
        <div class="award__title scrollObj" ref="titles">awards {{ award.year }}</div>
        <div class="award__item-main__body">
          <div class="body__inner">
            <div
              class="item__row scrollObj"
              v-for="(item, a) in award.lists"
              :key="a"
              :class="{
                pcHover: !$bowser.mobile
              }"
            >
              <div class="row__title">{{ item.title }}</div>
              <div class="row__project">{{ item.project.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="brands__container">
      <div class="brands__inner">
        <div class="brand__item scrollObj" v-for="(brand, index) in brands" :key="index">
          <img :src="brand.src" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import events from '@/utils/eventBus';

export default {
  name: 'views-award',
  data() {
    return {
      awards: [
        {
          year: 2021,
          lists: [
            {
              title: '荣获韩国 K-DESIGN AWARD 设计大奖金奖',
              project: {
                name: '沈阳华润瑞府展示中心',
              },
            },
            {
              title: '荣获韩国 K-DESIGN AWARD 设计大奖金奖',
              project: {
                name: '正荣青云宸院展示中心',
              },
            },
            {
              title: '荣获韩国 K-DESIGN AWARD 设计大奖金奖',
              project: {
                name: '太仓心望雅苑',
              },
            },
            {
              title: '荣获2021德国 ICONIC AWARDS — SELECTION',
              project: {
                name: '济南万科淄博翡翠东第地下会所',
              },
            },
            {
              title: '荣获2021德国 ICONIC AWARDS — SELECTION',
              project: {
                name: '沈阳华润瑞府展示中心',
              },
            },
            {
              title: '荣获法国 Novum Design Award 设计大奖金奖',
              project: {
                name: '济南万科淄博翡翠东第地下会所',
              },
            },
            {
              title: '荣获法国 Novum Design Award 设计大奖金奖',
              project: {
                name: '正荣青云宸院展示中心',
              },
            },
            {
              title: '荣获法国 Novum Design Award 设计大奖金奖',
              project: {
                name: '沈阳华润昭华里展示中心',
              },
            },
            {
              title: '荣获法国 Novum Design Award 设计大奖金奖',
              project: {
                name: '大发·碧桂园澜庭序展示中心',
              },
            },
            {
              title: '荣获2021 IPA英国国际房地产奖 AWARD WINNER',
              project: {
                name: '沈阳华润瑞府展示中心',
              },
            },
            {
              title: '荣获意大利A’Design Award奖金奖',
              project: {
                name: '济南万科淄博翡翠东第地下会所',
              },
            },
            {
              title: '荣获意大利A’Design Award奖金奖',
              project: {
                name: '沈阳华润瑞府售楼处',
              },
            },
            {
              title: '荣获意大利A’Design Award奖金奖',
              project: {
                name: '南京玄武映园美学生活馆',
              },
            },
            {
              title: '荣获意大利A’Design Award奖金奖',
              project: {
                name: '大发·碧桂园澜庭序展示中心',
              },
            },
            {
              title: '荣获意大利A’Design Award奖金奖',
              project: {
                name: '济南保利和光山语展示中心',
              },
            },
            {
              title: '荣获意大利A’Design Award奖金奖',
              project: {
                name: '万科淄博翡翠东第展示中心',
              },
            },
            {
              title: '荣获意大利A’Design Award奖金奖',
              project: {
                name: '绿都青云叙展示中心',
              },
            },
            {
              title: '荣获BERLIN Design Awards 2021 柏林设计大奖银奖',
              project: {
                name: '沈阳华润瑞府展示中心',
              },
            },
            {
              title: '荣获BERLIN Design Awards 2021 柏林设计大奖银奖',
              project: {
                name: '南京玄武映园美学生活馆',
              },
            },
          ],
        },
        {
          year: 2020,
          lists: [
            {
              title: '荣获法国双面神“GPDP AWARD”国际设计大奖金奖',
              project: {
                name: '济南万科淄博翡翠东第地下会所',
              },
            },
            {
              title: '荣获法国双面神“GPDP AWARD”国际设计大奖国际创新设计奖',
              project: {
                name: '沈阳华润瑞府展示中心',
              },
            },
            {
              title: '荣获伦敦 LICC 2020 Professional Official Selection',
              project: {
                name: '沈阳华润瑞府展示中心',
              },
            },
            {
              title: '荣获伦敦 LICC 2020 Professional Official Selection',
              project: {
                name: '太原融信時光之城示范区',
              },
            },
            {
              title: '荣获 NEW YORK Design Awards纽约设计大奖银奖',
              project: {
                name: '飞视之家',
              },
            },
            {
              title: '荣获 NEW YORK Design Awards纽约设计大奖银奖',
              project: {
                name: '上海中海 · 建国里样板间',
              },
            },
            {
              title: '荣获 NEW YORK Design Awards纽约设计大奖银奖',
              project: {
                name: '太原融信時光之城示范区',
              },
            },
            {
              title: '荣获 London Design Awards伦敦设计大奖金奖',
              project: {
                name: '太原融信時光之城示范区',
              },
            },
            {
              title: '荣获 London Design Awards伦敦设计大奖金奖',
              project: {
                name: '济南保利和光山语展示中心',
              },
            },
            {
              title: '荣获 London Design Awards伦敦设计大奖金奖',
              project: {
                name: '上海中海 · 建国里样板间',
              },
            },
            {
              title: '荣获 London Design Awards伦敦设计大奖金奖',
              project: {
                name: '飞视之家',
              },
            },
            {
              title: '荣获美国MUSE Design Awards铂金奖',
              project: {
                name: '太原融信时光之城',
              },
            },
            {
              title: '荣获美国MUSE Design Awards金奖',
              project: {
                name: '南京玄武映园美学生活馆',
              },
            },
            {
              title: '荣获美国MUSE Design Awards金奖',
              project: {
                name: '南京玄武映园美学生活馆',
              },
            },
            {
              title: '荣获美国MUSE Design Awards金奖',
              project: {
                name: '南京玄武映园美学生活馆',
              },
            },
            {
              title: '荣获法国 Novum Design Award 设计大奖金奖',
              project: {
                name: '太原融信時光之城',
              },
            },
            {
              title: '荣获法国 Novum Design Award 设计大奖金奖',
              project: {
                name: '上海崇明十里江湾营销中心',
              },
            },
            {
              title: '荣获法国 Novum Design Award 设计大奖金奖',
              project: {
                name: '济南保利和光山语展示中心',
              },
            },
            {
              title: '荣获法国 Novum Design Award 设计大奖金奖',
              project: {
                name: '上海中海 · 建国里样板间',
              },
            },
            {
              title: '荣获 Melbourne Design Awards墨尔本设计大奖金奖',
              project: {
                name: '太原融信時光之城',
              },
            },
            {
              title: '荣获 Melbourne Design Awards墨尔本设计大奖金奖',
              project: {
                name: '新城悦隽公园展示中心',
              },
            },
            {
              title: '荣获 Melbourne Design Awards墨尔本设计大奖金奖',
              project: {
                name: '济南保利和光山语展示中心',
              },
            },
            {
              title: '荣获意大利A’Design Award奖银奖',
              project: {
                name: '鲁商蓝岸公馆展示中心',
              },
            },
            {
              title: '荣获意大利A’Design Award奖银奖',
              project: {
                name: '常州星河国际样板间',
              },
            },
            {
              title: '荣获2020美国MUSE Design Awards白金奖',
              project: {
                name: '万科淄博翡翠东第展示中心',
              },
            },
            {
              title: '荣获2020美国MUSE Design Awards白金奖',
              project: {
                name: '上海崇明十里江湾营销中心',
              },
            },
            {
              title: '荣获2020美国MUSE Design Awards白金奖',
              project: {
                name: '天津宁河新城悦隽公馆展示中心',
              },
            },
            {
              title: '荣获2020美国MUSE Design Awards金奖',
              project: {
                name: '济南保利和光山语展示中心',
              },
            },
            {
              title: '荣获 亚太区室內设计大赛Sample Space银奖',
              project: {
                name: '大发·融悦新界「融悦台」',
              },
            },
            {
              title: '荣获2020德国 iF 设计大奖',
              project: {
                name: '融创.上海唐镇华枫展示中心',
              },
            },
            {
              title: '荣获2020德国 iF 设计大奖',
              project: {
                name: '万科翡翠书院展示中心',
              },
            },
          ],
        },
        {
          year: 2019,
          lists: [
            {
              title: '荣获法国双面神“GPDP AWARD”国际设计大奖地产类金奖',
              project: {
                name: '融创.上海唐镇华枫展示中心',
              },
            },
            {
              title: '荣获Paris Design Awards 巴黎设计大奖金奖',
              project: {
                name: '万科翡翠书院展示中心',
              },
            },
            {
              title: '荣获New York Design Awards 纽约设计大奖银奖',
              project: {
                name: '鲁商蓝岸公馆展示中心',
              },
            },
            {
              title: '第十四届金盘奖华东赛区｜年度最佳售楼空间奖',
              project: {
                name: '弘阳徐州新元大都会展示中心',
              },
            },
            {
              title: '第十四届金盘奖华东赛区｜年度最佳别墅空间奖',
              project: {
                name: '中海云麓里叠墅样板间',
              },
            },
            {
              title: '第十四届金盘奖华东赛区｜年度最佳主力户型空间奖',
              project: {
                name: '常州星河国际样板间',
              },
            },
            {
              title: '第十四届金盘奖华东赛区｜年度最佳主力户型空间奖',
              project: {
                name: '上海中海·建国里样板间',
              },
            },
            {
              title: '斩获2019美国 Architecture MasterPrize 建筑大师奖',
              project: {
                name: '融创.上海唐镇华枫展示中心',
              },
            },
            {
              title: '斩获2019美国 Architecture MasterPrize 建筑大师奖',
              project: {
                name: '中海.上东区展示中心',
              },
            },
            {
              title: '荣获法国双面神“GPDP AWARD”售楼空间国际创新设计奖',
              project: {
                name: '绿地水悦堂展示中心',
              },
            },
            {
              title: '荣获 London Design Awards 伦敦设计大奖金奖',
              project: {
                name: '融创.上海唐镇华枫展示中心',
              },
            },
            {
              title: '荣获第十四届金盘奖广东赛区最佳预售楼盘大奖',
              project: {
                name: '弘阳·博爱湖一號展示中心',
              },
            },
            {
              title: '荣获第十四届金盘奖华北、东北地区空间类-年度最佳售楼空间奖',
              project: {
                name: '青岛融信海月星湾展示中心',
              },
            },
          ],
        },
        {
          year: '2018-2017',
          lists: [
            {
              title: '荣获2018英国LICC Honorable mention',
              project: {
                name: '山东绿地国际金融中心IFC展示中心',
              },
            },
            {
              title: '荣获2018英国LICC Honorable mention',
              project: {
                name: '飞视设计之家',
              },
            },
            {
              title: "荣获意大利A’Design Award A'Design银奖",
              project: {
                name: '山东绿地国际金融中心IFC展示中心',
              },
            },
            {
              title: "荣获意大利A’Design Award A'Design银奖",
              project: {
                name: '绿地水悦堂展示中心',
              },
            },
            {
              title: "荣获意大利A’Design Award A'Design 设计奖",
              project: {
                name: '飞视设计之家',
              },
            },
            {
              title: '荣获法国双面神“GPDP AWARD”国际设计大奖',
              project: {
                name: '新城璞樾和山展示中心',
              },
            },
            {
              title: '荣获”国际设计传媒奖”2018年度展示空间大奖',
              project: {
                name: '新城璞樾和山展示中心',
              },
            },
            {
              title: '荣获美国IDA设计银奖',
              project: {
                name: '中海宁波九塘酌月样板房',
              },
            },
            {
              title: '荣获 2018 德国红点设计大奖',
              project: {
                name: '绿地水悦堂展示中心',
              },
            },
            {
              title: '荣获 2018 英国SBID设计奖',
              project: {
                name: '山东绿地国际金融中心IFC展示中心',
              },
            },
            {
              title: '荣获现代装饰传媒奖年度样板空间大奖',
              project: {
                name: ' FACE Interior Design',
              },
            },
            {
              title: '荣获APIPA香港亚太室内设计大奖',
              project: {
                name: ' FACE Interior Design',
              },
            },
            {
              title: 'APDC亚太室内设计精英邀请赛大奖',
              project: {
                name: ' FACE Interior Design',
              },
            },
          ],
        },
      ],
      brands: [
        {
          src: require('@/assets/images/awards/1.jpg'),
        },
        {
          src: require('@/assets/images/awards/2.jpg'),
        },
        {
          src: require('@/assets/images/awards/3.jpg'),
        },
        {
          src: require('@/assets/images/awards/4.jpg'),
        },
        {
          src: require('@/assets/images/awards/5.jpg'),
        },
        {
          src: require('@/assets/images/awards/6.jpg'),
        },
        {
          src: require('@/assets/images/awards/7.jpg'),
        },
        {
          src: require('@/assets/images/awards/8.jpg'),
        },
        {
          src: require('@/assets/images/awards/9.jpg'),
        },
        {
          src: require('@/assets/images/awards/10.jpg'),
        },
        {
          src: require('@/assets/images/awards/11.jpg'),
        },
        {
          src: require('@/assets/images/awards/12.jpg'),
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      events.$emit('pageMounted');
    });
  },
};
</script>

<style></style>
